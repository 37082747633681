<template>
  <v-app>
    <v-main>
      <v-skeleton-loader
          v-if="loader"
          type="image"
        ></v-skeleton-loader>
      <Main v-if="!loader"/>
    </v-main>
  </v-app>
</template>

<script>
import Main from './components/Main';
import {mapActions} from 'vuex';
export default {
  name: 'App',

  components: {
    Main,
  },

  data: () => ({
    loader: true
    //
  }),
  methods: {
    ...mapActions([
      'SETTINGS_get', 
      'TEMPLATES__GET',
      'ATTRACTIVENESS_RATE__GET',
      'SPECIAL_CASE__GET'
      ])
  },
  async mounted() {
    await this.TEMPLATES__GET();
    await this.ATTRACTIVENESS_RATE__GET();
    await this.SETTINGS_get();
    await this.SPECIAL_CASE__GET();
    this.loader = false;
  }
};
</script>
