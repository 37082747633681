<template>
  <v-card style="padding: 14px;
    margin: 10px;" elevation="2">
    <div>
      <v-btn @click="saveSetting">Save</v-btn>
      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Права доступа</span>
      <v-divider style="border-color: rgb(25 118 210); margin-bottom: 25px"></v-divider>
      <v-row>
        <v-col cols="3" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Администраторы</span>
        </v-col>
        <v-col cols="9" style="display: flex">
          <v-text-field label="Администраторы" placeholder dense solo></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Менеджеры</span>
        </v-col>
        <v-col cols="9" style="display: flex">
          <v-text-field label="Менеджеры" placeholder dense solo></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-bottom: 10px">
        <v-col cols="12" sm="3" md="3" style="display: flex"></v-col>
        <v-col cols="12" sm="4" md="4" style="display: flex">
          <span
            style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Курс валют</span>
        </v-col>
        <v-col cols="12" sm="5" md="5" style="display: flex">
          <span
            style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Ставка привлекаемого кредита</span>
        </v-col>
      </v-row>
      <div>
        <v-row v-for="item in currency" :key="item.name" style="margin-top: 0">
          <v-col cols="12" sm="3" md="3" style="display: flex; padding-top: 0; padding: 0">
            <span
              style="display: block; margin-top: 3px; margin-left: 14px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
            >{{item.name}}</span>
          </v-col>
          <v-col cols="12" sm="4" md="4" style="display: flex; padding-top: 0; padding: 0">
            <v-text-field
              @change="(value) => changeCurrenty('value', item, value)"
              :value="item.value"
              label="Ввести..."
              style=" margin-top: 0; margin-bottom: -11px;"
              placeholder
              dense
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" md="5" style="display: flex; padding-top: 0; padding: 0">
            <v-autocomplete
              :value="item.rateId"
              @change="(value) => changeCurrenty('rateId', item, value)"
              :items="ATTRACTIVENESS_RATE"
              item-text="name"
              item-value="id"
              style="margin-top: 0px; margin-left: 10px"
              dense
              chips
              small-chips
              label
              solo
            >
              <template v-slot:selection="data">{{ data.item.name }}</template>
            </v-autocomplete>
            <!-- <v-text-field
            label="..."
            style=" margin-top: 11px; margin-bottom: -11px;"
            placeholder
            dense
            solo
            ></v-text-field>-->
            <v-btn
              v-if="item.type == 'more'"
              color="red"
              style="border-radius: 50%; width: 40px; height: 40px; min-width: auto; margin-top: 0px; margin-left: 15px;"
              text
              @click="deleteCurrenty(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div v-else style="width: 55px"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              label="Ввести..."
              style="width: 100px;"
              placeholder
              dense
              solo
              v-model="newNameCurrenty"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" style="display: flex">
            <v-btn @click="addCurrenty">Добавить</v-btn>
          </v-col>
        </v-row>
      </div>

      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Типы гашения</span>
      <v-divider style="border-color: rgb(25 118 210); margin-bottom: 25px"></v-divider>
      <v-data-table
        :headers="repaymentTypeHeaders"
        :items="repaymentType"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        class="elevation-1"
      >
        <template v-slot:expanded-item="repaymentType">
          <td :colspan="repaymentType.headers.length" style="padding: 8px">
            <v-data-table
              :headers="monthHeaders"
              :items="monthDefault"
              item-key="name"
              hide-default-footer
              hide-default-header
            >
              <template v-slot:item.name="{item}">
                <div @click="openDialogRepayment({item, repaymentType})">{{item.name}}</div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogRepayment" persistent max-width="350px">
        <v-card>
          <v-card-title>
            <span
              class="text-h5"
            >{{dialogRepaymentInfo.repaymentType.item.name}} - {{dialogRepaymentInfo.item.name}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row
                v-for="i in dialogRepaymentInfo.item.value"
                :key="i"
                style="margin-bottom: -40px;"
              >
                <v-col cols="2" style="padding-left: 0">
                  <span
                    style="margin-top: 10px; display: block; font-size: 1.5em; color: rgb(25 118 210)"
                  >{{i}}</span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    append-icon="mdi-percent-outline"
                    solo
                    dense
                    v-model="dialogRepaymentValue[dialogRepaymentInfo.repaymentType.item.name][i]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogRepayment = false">Закрыть</v-btn>
            <v-btn color="blue darken-1" text @click="saveDialogRepayment">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="onAlertCurrenty" :multi-line="true">
        {{ alertCurrenty }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="onAlertCurrenty = false">Закрыть</v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="onAlertRepayment" :multi-line="true">
        {{ allertRepayment }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="onAlertRepayment = false">Закрыть</v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      newNameCurrenty: "",
      alertCurrenty: "Валюта с таким названием уже есть",
      onAlertCurrenty: false,
      expanded: [],
      singleExpand: false,
      monthDefault: [
        {
          name: "6 месяцев",
          value: 6
        },
        {
          name: "12 месяцев",
          value: 12
        },
        {
          name: "18 месяцев",
          value: 18
        },
        {
          name: "24 месяцев",
          value: 24
        },
        {
          name: "30 месяцев",
          value: 30
        },
        {
          name: "36 месяцев",
          value: 36
        },
        {
          name: "48 месяцев",
          value: 48
        },
        {
          name: "60 месяцев",
          value: 60
        }
      ],
      monthHeaders: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name"
        }
      ],
      repaymentTypeHeaders: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name"
        }
      ],
      dialogRepayment: false,
      dialogRepaymentInfo: {
        item: { value: 0, name: "" },
        repaymentType: { item: { name: 0 } }
      },
      dialogRepaymentValue: {},
      onAlertRepayment: false,
      allertRepayment: "Сумма не равно 100%"
    };
  },
  computed: {
    ...mapGetters([
      "TEMPLATES",
      "ATTRACTIVENESS_RATE",
      "CURRENTY__default",
      "CURRENTY__more",
      "CURRENTY__BYN",
      "REPAYMENT_TYPE__default"
    ]),

    currency() {
      console.log(this.CURRENTY__default, this.CURRENTY__more);
      return [...this.CURRENTY__default, ...this.CURRENTY__more];
    },
    repaymentType() {
      return [...this.REPAYMENT_TYPE__default];
    }
  },
  methods: {
    ...mapActions(["SETTINGS_save"]),
    ...mapMutations([
      "CURRENTY_addMore",
      "REPAYMENT_TYPE__CHANGE_DATA",
      "CURRENTY__CHANGE",
      "CURRENTY__DELETE"
    ]),
    saveSetting() {
      this.SETTINGS_save();
    },
    addCurrenty() {
      if (
        ![
          ...this.CURRENTY__default,
          ...this.CURRENTY__more,
          ...this.CURRENTY__BYN
        ].find(el => el.name == this.newNameCurrenty)
      ) {
        this.CURRENTY_addMore(this.newNameCurrenty);
        this.newNameCurrenty = "";
      } else {
        this.onAlertCurrenty = true;
      }
    },
    changeCurrenty(type, item, value) {
      console.log("--=", type, item, value);
      this.CURRENTY__CHANGE({ type, name: item.name, value });
    },
    deleteCurrenty(item) {
      this.CURRENTY__DELETE(item);
    },
    openDialogRepayment(info) {
      console.log(info);
      this.dialogRepayment = true;
      this.dialogRepaymentInfo = info;
      this.dialogRepaymentValue[
        this.dialogRepaymentInfo.repaymentType.item.name
      ] = {};
      let onData = false;
      let data = [];
      if (this.dialogRepaymentInfo.repaymentType.item.data) {
        data = this.dialogRepaymentInfo.repaymentType.item.data[
          this.dialogRepaymentInfo.item.value
        ];
        console.log("data", data);
        if (data) {
          onData = true;
        }
      }

      for (let i = 1; i <= this.dialogRepaymentInfo.item.value; i++) {
        console.log("-=");
        this.dialogRepaymentValue[
          this.dialogRepaymentInfo.repaymentType.item.name
        ][i] = onData ? data[i] : 0;
      }
    },
    saveDialogRepayment() {
      let sum = 0;
      for (let i = 1; i <= this.dialogRepaymentInfo.item.value; i++) {
        console.log(sum, "==");
        sum += Number(
          this.dialogRepaymentValue[
            this.dialogRepaymentInfo.repaymentType.item.name
          ][i]
        );
      }
      if (sum == 100) {
        console.log("+");
        console.log(this.repaymentType);
        this.REPAYMENT_TYPE__CHANGE_DATA({
          type: this.dialogRepaymentInfo.repaymentType.item.name,
          month: this.dialogRepaymentInfo.item.value,
          data: this.dialogRepaymentValue[
            this.dialogRepaymentInfo.repaymentType.item.name
          ]
        });
      } else {
        console.log("NOT -", sum, this.dialogRepaymentValue);
        this.onAlertRepayment = true;
      }
    }
  }
};
</script>

<style scoped>
</style>