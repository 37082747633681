export default {
	state: {
		templates: [],
		templatesFind: {}
	},
	getters: {
		TEMPLATES(state) {
			return state.templates.map((el) => { return { ...el.PROPERTY_VALUES, id: el.ID } })
		},
		TEMPLATE(state) {
			return (id) => {
				let result = {...state.templatesFind[id]}
				delete result.link
				return result;
			}
		}
	},
	mutations: {
		TEMPLATES__SAVE(state, value) {
			console.log(value);
			state.templates = value;
			let result = {};
			state.templates.forEach((item) => {
				result[item.ID] = { ...item.PROPERTY_VALUES, id: item.ID, link: item }
			})
			state.templatesFind = result;
			console.log(state.templatesFind, 'FIND');
		},
		TEMPLATES__ADD(state, value) {
			let idLink = state.templates.push({ ID: value.id, PROPERTY_VALUES: value.data });
			state.templatesFind[value.id] = { ...value.data, id: value.id, link: state.templates[idLink] }
		},
		TEMPLATES__CHANGE(state, value) {

			// state.templates = { ID: value.id, PROPERTY_VALUES: value.data };
			let editElement = state.templatesFind[value.id].link.PROPERTY_VALUES;
			for (let key of Object.keys(editElement)) {
				editElement[key] = value.data[key];
			}
			state.templates = [...state.templates];
			state.templatesFind[value.id] = { ...value.data, id: value.id, link: editElement }
			console.log(state.templates, state.templatesFind)
		},
		TEMPLATES__DELETE(state, id) {
			let idDelete = state.templates.findIndex((el) => el.ID == id);
			if (!idDelete == -1) {
				console.log('ID DELETE NOT')
			}
			state.templates.splice(idDelete, 1);
			delete state.templatesFind[id]
		}
	},
	actions: {
		TEMPLATES__GET({ commit }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'templates/get', {
					method: 'get'
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						commit('TEMPLATES__SAVE', json);
						resolve(true)
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		TEMPLATES__ADD({ commit }, data) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'template/add', {
					method: 'post',
					body: JSON.stringify(data)
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						commit('TEMPLATES__ADD', { id: json, data });
						resolve(true);
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		TEMPLATES__CHANGE({ commit }, { data, id }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'template/change', {
					method: 'post',
					body: JSON.stringify({ data, ID: id })
				}).then(async (res) => {
					let json = await res.json();
					if (json) {
						commit('TEMPLATES__CHANGE', { data, id })
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		TEMPLATES__DELETE({ commit }, id) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'template/delete', {
					method: 'delete',
					body: JSON.stringify({ ID: id })
				}).then(async (res) => {
					console.log(' GET - = ', res);
					commit('TEMPLATES__DELETE', id)

				}).catch(() => {
					resolve(false);
				})
			})
		}
	}
}