export default {
	state: {
		specialCases: [],
		specialCasesFind: {}
	},
	getters: {
		SPECIAL_CASES(state) {
			return state.specialCases.map((el) => { return { ...el.PROPERTY_VALUES, id: el.ID } })
		},
		SPECIAL_CASE(state) {
			return (id) => {
				let result = {...state.specialCasesFind[id]}
				delete result.link
				return result;
			}
		}
	},
	mutations: {
		SPECIAL_CASE__SAVE(state, value) {
			console.log(value);
			state.specialCases = value;
			let result = {};
			state.specialCases.forEach((item) => {
				result[item.ID] = { ...item.PROPERTY_VALUES, id: item.ID, link: item }
			})
			state.specialCasesFind = result;
			console.log(state.specialCasesFind, 'FIND');
		},
		SPECIAL_CASE__ADD(state, value) {
			let idLink = state.specialCases.push({ ID: value.id, PROPERTY_VALUES: value.data });
			state.specialCasesFind[value.id] = { ...value.data, id: value.id, link: state.specialCases[idLink] }
		},
		SPECIAL_CASE__CHANGE(state, value) {
			let editElement = state.specialCasesFind[value.id].link.PROPERTY_VALUES;
			for (let key of Object.keys(editElement)) {
				editElement[key] = value.data[key];
			}
			state.specialCases = [...state.specialCases];
			state.specialCasesFind[value.id] = { ...value.data, id: value.id, link: editElement }
			console.log(state.specialCases, state.specialCasesFind)
		},
		SPECIAL_CASE__DELETE(state, id) {
			let idDelete = state.specialCases.findIndex((el) => el.ID == id);
			if (!idDelete == -1) {
				console.log('ID DELETE NOT')
			}
			state.specialCases.splice(idDelete, 1);
			delete state.specialCasesFind[id]
		}
	},
	actions: {
		SPECIAL_CASE__GET({ commit }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'specialcase/get', {
					method: 'get'
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						commit('SPECIAL_CASE__SAVE', json);
						resolve(true)
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		SPECIAL_CASE__ADD({ commit }, data) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'specialcase/add', {
					method: 'post',
					body: JSON.stringify(data)
				}).then(async (res) => {
					console.log(' GET - = ', res);
					let json = await res.json();
					if (json != null) {
						commit('SPECIAL_CASE__ADD', { id: json, data });
						resolve(true);
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		SPECIAL_CASE__CHANGE({ commit }, { data, id }) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'specialcase/change', {
					method: 'post',
					body: JSON.stringify({ data, ID: id })
				}).then(async (res) => {
					let json = await res.json();
					if (json) {
						commit('SPECIAL_CASE__CHANGE', { data, id })
					}
				}).catch(() => {
					resolve(false);
				})
			})
		},
		SPECIAL_CASE__DELETE({ commit }, id) {
			return new Promise((resolve) => {
				fetch(this.state.url + 'specialcase/delete', {
					method: 'delete',
					body: JSON.stringify({ ID: id })
				}).then(async (res) => {
					console.log(' GET - = ', res);
					commit('SPECIAL_CASE__DELETE', id)

				}).catch(() => {
					resolve(false);
				})
			})
		}
	}
}