<template>
  <v-card style="padding: 14px;
    margin: 10px;" elevation="2">
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Сделка</span>
          <v-text-field label="Deal" placeholder dense solo></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5" md="5" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Тип клиента</span>
          <!-- <v-text-field label="Сlient type" placeholder ></v-text-field> -->
          <v-select :items="DEFAULT_FIELDS.typeClients" v-model="filterTemplate" dense solo></v-select>
        </v-col>
        <v-col cols="12" sm="5" offset="2" md="5" style="display: flex">
          <span style="padding-top: 10px; padding-right: 10px;">Тип договора</span>
          <!-- <v-text-field label="Type of contract" placeholder dense solo></v-text-field> -->
          <v-select
            :items="templateSelect"
            item-text="name"
            item-value="id"
            dense
            solo
            @change="changeTemplate"
          ></v-select>
        </v-col>
      </v-row>

      <span
        style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Основные сведения</span>
      <v-divider style="border-color: rgb(25 118 210)"></v-divider>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="12" md="12" style="display: flex; justify-content: flex-start">
          <span style="padding-top: 10px; padding-right: 10px;">Цена</span>
          <v-text-field
            v-model="calculateValue.price"
            type="number"
            label
            placeholder
            dense
            solo
            style="max-width: 300px; margin-right: 30px"
          ></v-text-field>
          <div v-show="!onSpecialCase" style="display: flex">
            <v-checkbox
              v-for="item in currencyValues"
              :label="item.name"
              color="info"
              :value="item.name"
              :key="item.name"
              hide-details
              class="mr-5"
              style="margin-top: 6px"
              v-model="calculateValue.currencyValues"
            >
              <template v-slot:label>
                <div>
                  <div v-if="item.more">
                    <v-hover v-slot="{hover}">
                      <div>
                        <span>{{item.name}}</span>
                        <v-icon
                          v-if="hover"
                          color="red"
                          small
                          style="margin-bottom: 3px;"
                          @click="deleteMore('currencyValues', item)"
                        >mdi-close</v-icon>
                        <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                      </div>
                    </v-hover>
                  </div>
                  <div v-else>{{item.name}}</div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-dialog v-show="!onSpecialCase" transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="height: 31px; width: 31px; margin-top: 7px"
                v-bind="attrs"
                v-on="on"
                @click="() => updateDataDialog('currencyValues')"
              >mdi-plus</v-icon>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" style="display: flex; justify-content: flex-start">
                      <div style="padding-top: 10px; padding-right: 10px;">Валюта</div>
                      <v-select
                        :value="valuesDialogsMore.currencyValues.name"
                        :items="currencyValuesMore"
                        item-text="name"
                        item-value="name"
                        solo
                        @change="(val) =>changeCurrentyMore('currencyValues', val)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = false; clearFieldsDialog('currencyValues')}"
                  >Отмена</v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = !saveFieldsDialog('currencyValues')}"
                  >Добавить</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <div v-show="!onSpecialCase">
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex;">
            <span style="padding-top: 10px; padding-right: 10px;">Участие клиента</span>
            <div style="display: flex">
              <v-checkbox
                v-for="item in involvementValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item"
                :key="item.name"
                hide-details
                v-model="calculateValue['involvementValues']"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{hover}">
                        <div>
                          <span>{{item.name}}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px;"
                            @click="deleteMore('involvementValues', item)"
                          >mdi-close</v-icon>
                          <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{item.name}}</div>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="height: 31px; width: 31px; margin-top: 7px"
                  v-bind="attrs"
                  v-on="on"
                >mdi-plus</v-icon>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="text-h5"></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" style="display: flex; justify-content: flex-start">
                        <div style="padding-top: 10px; padding-right: 10px;">Процент</div>
                        <v-text-field
                          label
                          solo
                          :append-icon="valuesDialogsMore.involvementValues.type == 'money' ? 'mdi-cash' : 'mdi-percent-outline'"
                          v-model="valuesDialogsMore.involvementValues.value"
                          @click:append="changeTypeInvolvement()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="() =>{dialog.value = false; clearFieldsDialog('involvementValues')}"
                    >Отмена</v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="() =>{dialog.value = !saveFieldsDialog('involvementValues')}"
                    >Добавить</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex;">
            <span
              style="dispay: block; width: 250px; padding-top: 0px; padding-right: 10px;"
            >Выкупная стоимость, вносимая после последнего платежа</span>
            <div style="display: flex">
              <v-text-field
                v-model="calculateValue.redemption"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span
              style="dispay: block; width: 250px; padding-top: 10px;"
            >Совокупная процентная ставка</span>
            <div style="display: flex">
              <v-text-field
                v-model="calculateValue.interestRate"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 0px">
          <v-col cols="7" style="display: flex; flex-direction: column">
            <span
              style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Валюта договора</span>
            <div style="display: flex">
              <v-checkbox
                v-for="item in contractCurrencyValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                v-model="calculateValue.contractCurrencyValues"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{hover}">
                        <div>
                          <span>{{item.name}}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px;"
                            @click="deleteMore('contractCurrencyValues', item)"
                          >mdi-close</v-icon>
                          <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{item.name}}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => updateDataDialog('contractCurrencyValues')"
                  >mdi-plus</v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" style="display: flex; justify-content: flex-start">
                          <div style="padding-top: 10px; padding-right: 10px;">Валюта</div>
                          <v-select
                            :value="valuesDialogsMore.contractCurrencyValues.name"
                            :items="contractCurrencyValuesMore"
                            item-text="name"
                            item-value="name"
                            solo
                            @change="(val) => changeCurrentyMore('contractCurrencyValues', val)"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = false; clearFieldsDialog('contractCurrencyValues')}"
                      >Отмена</v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = !saveFieldsDialog('contractCurrencyValues')}"
                      >Добавить</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="4" offset="1" style="display: flex; flex-direction: column">
            <span
              style="padding-top: 10px; padding-right: 10px; width: 250px; padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Увеличенный курс оплаты</span>
            <div style="display: flex">
              <v-text-field
                v-model="calculateValue.paymentRate"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 0px">
          <v-col cols="12" sm="12" md="12" style="display: flex; flex-direction: column;">
            <span
              style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Срок лизинга</span>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <v-checkbox
                v-for="item in leaseTermValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 12px; width: 30%"
                :value="item.name"
                :key="item.name"
                hide-details
                v-model="calculateValue['leaseTermValues']"
                :disabled="checkDisabledLeaseTermValues(item)"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{hover}">
                        <div>
                          <span>{{item.name}}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px;"
                            @click="deleteMore('leaseTermValues', item)"
                          >mdi-close</v-icon>
                          <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{item.name}}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 30%; height: 45px;">
                    <v-icon
                      style="height: 31px; width: 31px; margin-top: 7px; margin-left: -4px;"
                      v-bind="attrs"
                      v-on="on"
                    >mdi-plus</v-icon>
                  </div>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" style="display: flex; justify-content: flex-start">
                          <div style="padding-top: 10px; padding-right: 10px;">Месяцев</div>
                          <v-text-field
                            label="5"
                            solo
                            append
                            v-model="valuesDialogsMore.leaseTermValues.value"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = false; clearFieldsDialog('leaseTermValues')}"
                      >Отмена</v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = !saveFieldsDialog('leaseTermValues')}"
                      >Добавить</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
        >Погашение участия лизинговой компании</span>
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex;">
            <span style="padding-top: 10px; padding-right: 10px;">Тип гашения</span>
            <div style="display: flex">
              <v-checkbox
                v-for="item in repaymentTypeValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                :disabled="checkDisabledFields(item)"
                v-model="calculateValue['repaymentTypeValues']"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{hover}">
                        <div>
                          <span>{{item.name}}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px;"
                            @click="deleteMore('repaymentTypeValues', item)"
                          >mdi-close</v-icon>
                          <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{item.name}}</div>
                  </div>
                </template>
              </v-checkbox>

              <v-dialog transition="dialog-bottom-transition" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="()=> {setFormatDataDialog('repaymentTypeValues');}"
                  >mdi-plus</v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px;">
                        <v-col cols="3" style="display: flex; justify-content: flex-start">
                          <div style="margin-top: 10px; display: block;">Название</div>
                        </v-col>
                        <v-col cols="8" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.repaymentTypeValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore.repaymentTypeValues.value"
                        :key="index"
                        style="margin-bottom: -40px;"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="margin-top: 10px; display: block; font-size: 1.5em; color: rgb(25 118 210)"
                          >{{index + 1}}</span>
                        </v-col>
                        <v-col cols="5" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            type="number"
                            append-icon="mdi-percent-outline"
                            v-model="valuesDialogsMore.repaymentTypeValues.value[index]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start; margin-top: 14px;"
                        >{{ sumDialogField('repaymentTypeValues', index) }}</v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = false; clearFieldsDialog('repaymentTypeValues')}"
                      >Отмена</v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = !saveFieldsDialog('repaymentTypeValues')}"
                      >Добавить</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
        >Страховка</span>
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex;">
            <span style="padding-top: 10px; padding-right: 10px;">Тариф</span>
            <div style="display: flex">
              <v-text-field
                v-model="calculateValue.insuranceRate"
                label
                placeholder
                solo
                dense
                type="number"
                append-icon="mdi-percent-outline"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex;">
            <span style="padding-top: 10px; padding-right: 10px;">Тип страховки</span>
            <div style="display: flex">
              <v-checkbox
                v-for="item in insuranceTypeValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                hide-details
                :disabled="checkDisabledFields(item)"
                v-model="calculateValue.insuranceTypeValues"
              >
                <template v-slot:label>
                  <div>
                    <div v-if="item.more">
                      <v-hover v-slot="{hover}">
                        <div>
                          <span>{{item.name}}</span>
                          <v-icon
                            v-if="hover"
                            color="red"
                            small
                            style="margin-bottom: 3px;"
                            @click="deleteMore('insuranceTypeValues', item)"
                          >mdi-close</v-icon>
                          <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-else>{{item.name}}</div>
                  </div>
                </template>
              </v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="()=> {setFormatDataDialog('insuranceTypeValues')}"
                  >mdi-plus</v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px;">
                        <v-col cols="3" style="display: flex; justify-content: flex-start">
                          <div style="margin-top: 10px; display: block;">Название</div>
                        </v-col>
                        <v-col cols="8" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.insuranceTypeValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore.insuranceTypeValues.value"
                        :key="index"
                        style="margin-bottom: -40px;"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="margin-top: 10px; display: block; font-size: 1.5em; color: rgb(25 118 210)"
                          >{{index + 1}}</span>
                        </v-col>
                        <v-col cols="5" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            type="number"
                            append-icon="mdi-percent-outline"
                            v-model="valuesDialogsMore.insuranceTypeValues.value[index]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start; margin-top: 14px;"
                        >{{ sumDialogField('insuranceTypeValues', index) }}</v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = false; clearFieldsDialog('insuranceTypeValues')}"
                      >Отмена</v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = !saveFieldsDialog('insuranceTypeValues')}"
                      >Добавить</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <span
          style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
        >Амортизация</span>
        <v-divider style="border-color: rgb(25 118 210)"></v-divider>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="12" md="12" style="display: flex">
            <span style="padding-top: 10px; padding-right: 10px;">Выплаты по амортизации</span>
            <div style="display: flex">
              <v-checkbox
                v-for="item in depreciationValues"
                :label="item.name"
                color="info"
                class="mr-5"
                style="margin-top: 6px"
                :value="item.name"
                :key="item.name"
                :disabled="checkDisabledFields(item)"
                hide-details
              ></v-checkbox>
              <v-dialog transition="dialog-bottom-transition" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn style="margin-left: -9px; margin-top: 3px;" fab dark small color="indigo" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
                  </v-btn>-->
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="calculateValue.leaseTermValues.length != 1"
                    @click="()=> {setFormatDataDialog('depreciationValues')}"
                  >mdi-plus</v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5"></v-card-title>
                    <v-card-text>
                      <v-row style="margin-bottom: -30px;">
                        <v-col cols="3" style="display: flex; justify-content: flex-start">
                          <div style="margin-top: 10px; display: block;">Название</div>
                        </v-col>
                        <v-col cols="8" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            v-model="valuesDialogsMore.depreciationValues.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        v-for="(i, index) in valuesDialogsMore.depreciationValues.value"
                        :key="index"
                        style="margin-bottom: -40px;"
                      >
                        <v-col cols="2" offset="1" style="padding-left: 0">
                          <span
                            style="margin-top: 10px; display: block; font-size: 1.5em; color: rgb(25 118 210)"
                          >{{index + 1}}</span>
                        </v-col>
                        <v-col cols="5" style="display: flex; justify-content: flex-start">
                          <v-text-field
                            label
                            solo
                            append
                            type="number"
                            append-icon="mdi-percent-outline"
                            v-model="valuesDialogsMore.depreciationValues.value[index]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          style="display: flex; justify-content: flex-start; margin-top: 14px;"
                        >{{ sumDialogField('depreciationValues', index) }}</v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = false; clearFieldsDialog('depreciationValues')}"
                      >Отмена</v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="() =>{dialog.value = !saveFieldsDialog('depreciationValues')}"
                      >Добавить</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: 10px">
          <v-col cols="12" sm="3" md="3">
            <span
              style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Дата графика</span>
            <v-dialog
              ref="dialogDateSchedule"
              v-model="dialog.date.schedule"
              :return-value.sync="calculateValue.date.schedule"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.schedule"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.schedule" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog.date.schedule = false">Отмена</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateSchedule.save(calculateValue.date.schedule)"
                >OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" offset="1" sm="3" md="3">
            <span
              style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Дата задатка</span>
            <v-dialog
              ref="dialogDateDeposit"
              v-model="dialog.date.deposit"
              :return-value.sync="calculateValue.date.deposit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.deposit"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.deposit" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog.date.deposit = false">Отмена</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateDeposit.save(calculateValue.date.deposit)"
                >OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" offset="1" sm="3" md="3">
            <span
              style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
            >Дата 1 платежа</span>
            <v-dialog
              ref="dialogDateFirstPay"
              v-model="dialog.date.firstPay"
              :return-value.sync="calculateValue.date.firstPay"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="calculateValue.date.firstPay"
                  label
                  append-icon="mdi-calendar"
                  readonly
                  solo
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="calculateValue.date.firstPay" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog.date.firstPay = false">Отмена</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateFirstPay.save(calculateValue.date.firstPay)"
                >OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="3" md="3">
          <span
            style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
          >Руководитель проекта</span>
          <v-text-field label placeholder solo dense></v-text-field>
        </v-col>
        <v-col cols="12" offset="1" sm="3" md="3">
          <v-btn style="margin-top: 24px" color="primary">History</v-btn>
        </v-col>
        <v-col cols="12" offset="1" sm="3" md="3">
          <v-btn style="margin-top: 24px" color="primary" @click="test">Test calc</v-btn>
          <v-btn style="margin-top: 24px" color="primary" @click="getCalculate">calc</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      filterTemplate: "0",
      moreFields: {
        currencyValues: [],
        involvementValues: [],
        contractCurrencyValues: [],
        leaseTermValues: [],
        repaymentTypeValues: [],
        insuranceTypeValues: [],
        depreciationValues: []
      },
      valuesDialogsMore: {
        currencyValues: {
          name: "",
          value: ""
        },
        involvementValues: {
          name: "",
          value: "",
          type: "percent"
        },
        contractCurrencyValues: {
          name: "",
          value: ""
        },
        leaseTermValues: {
          name: "",
          value: ""
        },
        insuranceTypeValues: {
          name: "",
          value: []
        },
        repaymentTypeValues: {
          name: "",
          value: ""
        },
        depreciationValues: {
          name: "",
          value: []
        }
      },
      dialog: {
        currencyValues: false,
        date: {
          schedule: false,
          deposit: false,
          firstPay: false
        }
      },
      calculateValue: {
        price: 0,
        leaseTermValues: [],
        repaymentTypeValues: [],
        redemption: 1,
        contractCurrencyValues: [],
        currencyValues: [],
        involvementValues: [],
        insuranceRate: 0,
        insuranceTypeValues: [],
        depreciationValues: [],

        date: {
          schedule: "",
          deposit: "",
          firstPay: ""
        }
      },
      onSpecialCase: false
    };
  },
  computed: {
    ...mapGetters([
      "TEMPLATES",
      "TEMPLATE",
      "CURRENTY__BYN",
      "CURRENTY__default",
      "CURRENTY__more",
      "ATTRACTIVENESS_RATE__ITEM",
      "DEFAULT_FIELDS",
      "SPECIAL_CASES",
      "SPECIAL_CASE"
    ]),
    currencyValues() {
      return [
        ...this.CURRENTY__BYN,
        ...this.CURRENTY__default,
        ...this.moreFields.currencyValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    currencyValuesMore() {
      return this.CURRENTY__more.filter(el => {
        return !this.currencyValues.find(elUs => elUs.name == el.name);
      });
    },
    involvementValues() {
      return [
        ...this.DEFAULT_FIELDS.involvementValues,
        ...this.moreFields.involvementValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    contractCurrencyValues() {
      return [
        ...this.DEFAULT_FIELDS.contractCurrencyValues,
        ...this.moreFields.contractCurrencyValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    contractCurrencyValuesMore() {
      return this.CURRENTY__more.filter(el => {
        return !this.contractCurrencyValues.find(elUs => elUs.name == el.name);
      });
    },
    leaseTermValues() {
      return [
        ...this.DEFAULT_FIELDS.leaseTermValues,
        ...this.moreFields.leaseTermValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    repaymentTypeValues() {
      return [
        ...this.DEFAULT_FIELDS.repaymentTypeValues,
        ...this.moreFields.repaymentTypeValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    insuranceTypeValues() {
      return [
        ...this.DEFAULT_FIELDS.insuranceTypeValues.slice(0, -1),
        ...this.moreFields.insuranceTypeValues.map(el => ({
          ...el,
          more: true
        })),
        ...this.DEFAULT_FIELDS.insuranceTypeValues.slice(-1)
      ];
    },
    depreciationValues() {
      return [
        ...this.DEFAULT_FIELDS.depreciationValues,
        ...this.moreFields.depreciationValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    templateSelect() {
      let result = [];
      let template = [
        ...this.TEMPLATES,
        ...this.SPECIAL_CASES.map(el => ({
          ...el,
          typeClient: 0,
          specialCase: true
        }))
      ];
      result = template.filter(el => el.typeClient == this.filterTemplate);
      return result;
    }
  },
  methods: {
    // changeValues(typeDialog, typeValue, value) {
    //   this.valuesDialogsMore['typeDialog']['typeValue'] = value
    // }
    moment,
    getTemplate(value) {
      let result;
      if (this.TEMPLATE(value).id) {
        result = this.TEMPLATE(value);
      } else if (this.SPECIAL_CASE(value).id) {
        result = {
          ...this.SPECIAL_CASE(value),
          typeClient: 0,
          specialCase: true
        };
      }
      return result;
    },
    getMounthName(value) {
      let result = "";
      if (value % 10 == 1) {
        result = "месяц";
      } else if (
        [5, 6, 7, 8, 9, 0].includes(value % 10) ||
        [11, 12, 13, 14].includes(value % 100)
      ) {
        result = "месяцев";
      } else {
        result = "месяца";
      }
      return result;
    },
    sumDialogField(type, index) {
      console.log(this.valuesDialogsMore, type, index);
      return this.valuesDialogsMore[type].value
        .slice(0, index + 1)
        .reduce(
          (partial_sum, a) => (Number(partial_sum) + Number(a)).toFixed(2),
          0
        );
    },
    currencyValuesItem(name) {
      let item = [
        ...this.CURRENTY__BYN,
        ...this.CURRENTY__default,
        ...this.CURRENTY__more
      ].find(el => el.name == name);
      item.rate = this.ATTRACTIVENESS_RATE__ITEM(item.rateId);
      return item;
    },
    leaseTermValuesItem(name) {
      let item = [
        ...this.DEFAULT_FIELDS.leaseTermValues,
        ...this.moreFields.leaseTermValues.map(el => ({
          ...el,
          more: true
        }))
      ].find(el => el.name == name);
      return item;
    },
    clearFieldsDialog(typeDialog) {
      for (let key in this.valuesDialogsMore[typeDialog]) {
        this.valuesDialogsMore[typeDialog][key] = "";
      }
    },
    updateDataDialog(typeDialog) {
      this.valuesDialogsMore[typeDialog] = { ...this[typeDialog + "More"][0] };
    },
    saveFieldsDialog(typeDialog) {
      if (typeDialog == "involvementValues") {
        if (this.valuesDialogsMore[typeDialog].type == "money") {
          this.valuesDialogsMore[typeDialog].name = this.valuesDialogsMore[
            typeDialog
          ].value;
        } else {
          this.valuesDialogsMore[typeDialog].name =
            this.valuesDialogsMore[typeDialog].value + "%";
        }
      }
      if (typeDialog == "leaseTermValues") {
        this.valuesDialogsMore[typeDialog].name =
          this.valuesDialogsMore[typeDialog].value +
          " " +
          this.getMounthName(this.valuesDialogsMore[typeDialog].value);
      }
      if (
        ![
          ...this.DEFAULT_FIELDS[typeDialog],
          ...this.moreFields[typeDialog]
        ].find(el => el.name == this.valuesDialogsMore[typeDialog].name) &&
        this.valuesDialogsMore[typeDialog].name != ""
      ) {
        this.moreFields[typeDialog].push({
          ...this.valuesDialogsMore[typeDialog]
        });
        this.clearFieldsDialog(typeDialog);
        return true;
      } else {
        return false;
      }
    },
    changeTemplate(val) {
      this.calculateValue.template = this.getTemplate(val);
      console.log("FFF", this.calculateValue.template);
      if (this.calculateValue.template.specialCase) {
        console.log("SPECIAL CASE");
      }
    },
    changeTypeInvolvement() {
      this.valuesDialogsMore.involvementValues.type =
        this.valuesDialogsMore.involvementValues.type != "money"
          ? "money"
          : "percent";
    },
    changeCurrentyMore(typeDialog, val) {
      this.valuesDialogsMore[typeDialog] = {
        ...this.CURRENTY__more.find(el => el.name == val)
      };
    },
    checkDisabledLeaseTermValues(item) {
      let result = false;
      let blockList = [
        "repaymentTypeValues",
        "insuranceTypeValues",
        "depreciationValues"
      ];
      let valueMounth = null;
      blockList.forEach(field => {
        let checkValueActiveMore = this.calculateValue[field].find(elCalc => {
          return this.moreFields[field].find(elMore => elMore.name == elCalc);
        });
        if (checkValueActiveMore != undefined) {
          valueMounth = this.moreFields[field].find(
            elMore => elMore.name == checkValueActiveMore
          ).value.length;
        }
        if (checkValueActiveMore) {
          console.log("checkValueActiveMore", checkValueActiveMore);
        }
      });
      if (valueMounth != null) {
        result = valueMounth != item.value;
      }
      return result;
    },
    checkDisabledFields(item) {
      console.log("%T ", item, this.calculateValue.leaseTermValues);
      let blockList = [
        "repaymentTypeValues",
        "insuranceTypeValues",
        "depreciationValues"
      ];
      let result = false;
      if (item.more) {
        if (this.calculateValue.leaseTermValues.length > 1) {
          result = true;
        } else {
          if (this.calculateValue.leaseTermValues.length == 1) {
            let mounth = this.leaseTermValues.find(
              el => this.calculateValue.leaseTermValues[0] == el.name
            ).value;
            console.log("---", mounth);
            if (item.value.length != mounth) {
              result = true;
            }
          } else {
            let valueMounth = null;
            blockList.forEach(field => {
              console.log("-==");
              let checkValueActiveMore = this.calculateValue[field].find(
                elCalc => {
                  return this.moreFields[field].find(
                    elMore => elMore.name == elCalc
                  );
                }
              );
              if (checkValueActiveMore != undefined) {
                valueMounth = this.moreFields[field].find(
                  elMore => elMore.name == checkValueActiveMore
                ).value.length;
              }
              if (checkValueActiveMore) {
                console.log("checkValueActiveMore", checkValueActiveMore);
              }
            });
            if (valueMounth != null) {
              result = valueMounth != item.value.length;
            }
          }
        }
      }
      return result;
    },

    deleteMore(typeDialog, val) {
      let deleteId = this.moreFields[typeDialog].findIndex(
        el => el.name == val.name
      );
      console.log("CALC", this.calculateValue, "=", val);
      let index = this.calculateValue[typeDialog].findIndex(
        el => el == val.name
      );
      if (index != -1) {
        this.calculateValue[typeDialog].splice(index, 1);
      }

      this.moreFields[typeDialog].splice(deleteId, 1);
    },
    setDataStart() {
      this.calculateValue.date.schedule = moment().format("YYYY-MM-DD");
      this.calculateValue.date.deposit = moment()
        .add(5, "d")
        .format("YYYY-MM-DD");
      this.calculateValue.date.firstPay = moment()
        .add(30, "d")
        .format("YYYY-MM-DD");
    },
    setFormatDataDialog(type) {
      let itemLeaseTerm = this.leaseTermValuesItem(
        this.calculateValue.leaseTermValues[0]
      );
      let countMonth = itemLeaseTerm.value;
      if (this.calculateValue.leaseTermValues.length == 1) {
        this.valuesDialogsMore[type].value = [...Array(countMonth)].map(
          () => ""
        );
      } else {
        console.log("ERROR - ошибка по месяцам");
      }
    },

    test() {
      let objCalc = {
        deal: {
          example: "инфа из сделки будет"
        },
        template: {
          typeClient: 0,
          exampletypeClient: "0 - физ-лицо, 1 - юр-лицо",
          VATContract: 5,
          VATRevard: 7,
          VATInsurance: 10,
          VATMore: 9
        },
        price: this.calculateValue.price,
        currency: [
          {
            name: "USD",
            value: 2.56,
            raise: 3.5
          }
        ],
        currencyUpdate: this.currencyValues,
        involvement: this.calculateValue.involvementValues,
        redemption: this.calculateValue.redemption,
        exapleRedemption: "значение в процентах - Выкупная стоимость",
        interestRate: this.calculateValue.interestRate,
        exapleInterestRate:
          "значение в процентах - совокупная процентная ставка",
        contractCurrency: [
          {
            name: "BYN",
            value: 1,
            raise: 4.5
          }
        ],
        paymentRate: this.calculateValue.paymentRate,
        leaseTerm: [
          { name: "6 месяцев", value: 6 },
          { name: "12 месяцев", value: 12 }
        ],
        repaymentType: [
          {
            type: "peer",
            months: { "6": [4, 5.5, 6, 7, 5, 9] },
            example:
              "тут будут расписаны проценты помесяцам или равный(peer) по всем, или массив месяцев будет и type пустой"
          }
        ],
        insurance: {
          rate: this.calculateValue.insuranceRate,
          type: "monthly",
          months: { "6": [1, 2, 3, 4, 5, 6] },
          example:
            " type - или название или массив месяцев будет.  - monthly - quarterly - one - not"
        },
        depreciation: {
          type: "standart",
          months: { "6": [1, 2, 3, 4, 5, 6] },
          exaple: "тип standart - peer"
        },
        more: {
          examle: "это потом"
        },
        date: {
          ...this.calculateValue.date
        }
      };
      fetch("https://dev56.support.by/leasingAPI/public/leasing/create", {
        method: "post",
        body: JSON.stringify(objCalc)
      }).then(res => {
        console.log("RESULT", res);
      });
      console.log("--", objCalc);
    },
    getCalculate() {
      let listError = {
        template: "Не выбран договор",
        deal: "Не выбрана сделка",
        price: "Не заполнена стоимость",
        currencyValues: "Не выбрана валюта",
        contractCurrencyValues: "Не выбрана валюта",
        involvementValues: "Не выбрано участие клиента",
        interestRate: "ERROR interest",
        paymentRate: "ERROR paymentRate",
        leaseTermValues: "ERROR lease Term",
        repaymentTypeValues: " repaymentType",
        insuranceRate: " insuranceRate"
      };
      let examinationDataCaluculation = () => {
        let onError = false;
        let message = "";
        // if (!this.calculateValue.deal) {
        //   // error = listError.deal;
        //   // onError = true
        // } else
        if (!this.calculateValue.template?.name) {
          message = listError.template;
          onError = true;
        } else if (!this.calculateValue.price) {
          message = listError.price;
          onError = true;
        } else if (this.calculateValue.currencyValues.length == 0) {
          message = listError.currencyValues;
          onError = true;
        } else if (this.calculateValue.contractCurrencyValues.length == 0) {
          message = listError.contractCurrencyValues;
          onError = true;
        } else if (this.calculateValue.involvementValues.length == 0) {
          message = listError.involvementValues;
          onError = true;
        } else if (!this.calculateValue.interestRate) {
          message = listError.interestRate;
          onError = true;
        } else if (!this.calculateValue.paymentRate) {
          message = listError.paymentRate;
          onError = true;
        } else if (this.calculateValue.leaseTermValues.length == 0) {
          message = listError.leaseTermValues;
          onError = true;
        } else if (this.calculateValue.repaymentTypeValues.length == 0) {
          message = listError.repaymentTypeValues;
          onError = true;
        } else if (!this.calculateValue.insuranceRate) {
          message = listError.repaymentTypeValues;
          onError = true;
        }

        return { onError, message };
      };
      let error = examinationDataCaluculation();
      let collectionData = () => {
        let dataCalc = {
          deal: "",
          price: this.calculateValue.price,
          involvementValues: this.calculateValue.involvementValues,
          currency: this.calculateValue.currencyValues.map(el =>
            this.currencyValuesItem(el)
          ),
          contractCurrency: this.calculateValue.contractCurrencyValues.map(el =>
            this.currencyValuesItem(el)
          ),
          redemption: this.calculateValue.redemption,
          interestRate: this.calculateValue.interestRate,
          paymentRate: this.calculateValue.paymentRate,
          leaseTerm: this.calculateValue.leaseTermValues.map(el =>
            this.leaseTermValuesItem(el)
          ),
          repaymentType: this.calculateValue.repaymentTypeValues,
          insurance: {
            rate: this.calculateValue.insuranceRate
          }
          // if () {

          // }
        };
        return dataCalc;
      };
      console.log(error);
      console.log("CALC", this.calculateValue);
      if (!error.onError) {
        console.log(collectionData(), "_-==-=-");
      }

      let objCalc = {
        deal: {
          example: "инфа из сделки будет"
        },
        template: {
          typeClient: 0,
          exampletypeClient: "0 - физ-лицо, 1 - юр-лицо",
          VATContract: 5,
          VATRevard: 7,
          VATInsurance: 10,
          VATMore: 9
        },
        price: this.calculateValue.price,
        currency: [
          {
            name: "USD",
            value: 2.56,
            raise: 3.5
          }
        ],
        currencyUpdate: this.currencyValues,
        involvement: this.calculateValue.involvementValues,
        redemption: this.calculateValue.redemption,
        exapleRedemption: "значение в процентах - Выкупная стоимость",
        interestRate: this.calculateValue.interestRate,
        exapleInterestRate:
          "значение в процентах - совокупная процентная ставка",
        contractCurrency: [
          {
            name: "BYN",
            value: 1,
            raise: 4.5
          }
        ],
        paymentRate: this.calculateValue.paymentRate,
        leaseTerm: [
          { name: "6 месяцев", value: 6 },
          { name: "12 месяцев", value: 12 }
        ],
        repaymentType: [
          {
            type: "peer",
            months: { "6": [4, 5.5, 6, 7, 5, 9] },
            example:
              "тут будут расписаны проценты помесяцам или равный(peer) по всем, или массив месяцев будет и type пустой"
          }
        ],
        insurance: {
          rate: this.calculateValue.insuranceRate,
          type: "monthly",
          months: { "6": [1, 2, 3, 4, 5, 6] },
          example:
            " type - или название или массив месяцев будет.  - monthly - quarterly - one - not"
        },
        depreciation: {
          type: "standart",
          months: { "6": [1, 2, 3, 4, 5, 6] },
          exaple: "тип standart - peer"
        },
        more: {
          examle: "это потом"
        },
        date: {
          ...this.calculateValue.date
        }
      };
      // fetch("https://dev56.support.by/leasingAPI/public/createLeasing", {
      //   method: "post",
      //   body: JSON.stringify(objCalc)
      // }).then(res => {
      //   console.log("RESULT", res);
      // });
      console.log("--", objCalc);
    }
  },
  mounted() {
    this.setDataStart();
  }
};
</script>

<style scoped>
</style>