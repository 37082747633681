import currenty from './currenty';
import repaymentType from './repaymentType';
export default {
	state: {
		currenty: {
			BYN: [
				{
					name: "BYN",
					value: 1,
					rateId: null
				}
			],
			default: [
				{
					name: "USD",
					value: 0,
					rateId: null
				},
				{
					name: "EUR",
					value: 0,
					rateId: null
				},
				{
					name: "RUB",
					value: 0,
					rateId: null
				}
			],
			more: [
				{
					name: "GBT",
					value: 0,
					rateId: null
				},
				{
					name: "FTG",
					value: 0,
					rateId: null
				}
			]
		},
		repaymentType: {
			default: [
				{
					name: 'Ускоренный'
				},
				{
					name: 'Медленный'
				}
			],
			more: [

			]
		}
	},
	getters: {
		SETTINGS(state) {
			return state.SETTINGS
		}
	},
	mutations: {
		SETTINGS_update(state, settings) {
			for (let i in settings) {
				state[i] = settings[i]
			}
			console.log('settings = ', state, window.localStorage)
		}

	},
	actions: {
		SETTINGS_save({ state }) {
			window.localStorage.setItem('settings', JSON.stringify(state));
			console.log(window.localStorage)
			fetch(this.state.url + 'settings/save', {
				method: 'post',
				body: JSON.stringify(state)
			}).then((res) => {
				console.log('SAVE', res)
			})
		},
		SETTINGS_get({ commit, state }) {
			console.log('--=')
			if (window.localStorage.getItem('settings') != null) {
				console.log('++');
				commit('SETTINGS_update', JSON.parse(window.localStorage.getItem('settings')))
			} else {
				commit('SETTINGS_update', {
					currenty: {
						BYN: [
							{
								name: "BYN",
								value: 1
							}
						],
						default: [
							{
								name: "USD",
								value: 0
							},
							{
								name: "EUR",
								value: 0
							},
							{
								name: "RUB",
								value: 0
							}
						],
						more: [
							{
								name: "GBT",
								value: 0
							},
							{
								name: "FTG",
								value: 0
							}
						]
					},
					repaymentType: {
						default: [
							{
								name: 'Ускоренный'
							},
							{
								name: 'Медленный'
							}
						],
						more: [

						]
					}
				})
			}
			fetch(this.state.url + 'settings/get', {
				method: 'get'
			}).then((res) => {
				console.log(' GET - = ', res)
			})

			console.log(state, 'STATE')
		}

	},
	modules: {
		currenty,
		repaymentType
	}
}