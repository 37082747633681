<template>
  <div>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="blue"></v-tabs-slider>
      <v-tab v-for="item in menu" :href="`#${item.value}`" :key="item.value">{{ item.label }}</v-tab>
    </v-tabs>
    <v-tabs-items :value="tab">
      <v-tab-item :value="menu[0].value">
        <calculator />
      </v-tab-item>
      <v-tab-item :value="menu[1].value">
        <settings/>
      </v-tab-item>
      <v-tab-item :value="menu[2].value">
        <catalog/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Calculator from "./tabs/Сalculator.vue";
import Settings from "./tabs/Settings.vue";
import Catalog from "./tabs/Catalog.vue"
export default {
  data() {
    return {
      menu: [
        {
          label: "Калькулятор",
          value: "calc"
        },
        {
          label: "Настройки",
          value: "settings"
        },
        {
          label: "Справочник",
          value: "catalog"
        }
      ],
      text:
        "",
      tab: "calc"
    };
  },
  components: {
    Calculator,
    Settings,
    Catalog
  }
};
</script>

<style scoped>
</style>