<template>
  <v-card style="padding: 14px;
    margin: 10px;" elevation="2">
    <div>
      <!-- <span
      style="display: block; margin-top: 25px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Ставка привлекаемого кредита</span>-->

      <v-expansion-panels focusable multiple>
        <v-expansion-panel>
          <v-expansion-panel-header
            style="font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Шаблон договора</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <!-- <span
              style="display: block; margin-top: 15px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
            >Шаблон договора</span>
              <v-divider style="border-color: rgb(25 118 210)"></v-divider>-->
              <v-row style="margin-top: 20px;">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
                </v-col>
                <v-col cols="8" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field v-model="newTemplate.name" label placeholder dense solo></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">НДС на контрактную стоимость</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="newTemplate.VATContract"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">НДС на вознаграждение</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="newTemplate.VATRevard"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">НДС на страховку</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="newTemplate.VATInsurance"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">НДС на доп. расходы</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="newTemplate.VATMore"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col
                  cols="8"
                  offset="4"
                  style="display: flex; padding-top: 4px; padding-bottom: 4px"
                >
                  <div style="display: flex">
                    <v-checkbox
                      style="margin-right: 17px; margin-top: 0"
                      v-model="newTemplate.typeClient"
                      label="Физ. ливо"
                      value="0"
                    ></v-checkbox>
                    <v-checkbox
                      style="margin-top: 0"
                      v-model="newTemplate.typeClient"
                      label="Юр. лицо"
                      value="1"
                    ></v-checkbox>
                  </div>
                  <v-btn
                    @click="addTemplate"
                    style="margin-left: auto"
                    color="primary"
                    elevation="2"
                  >Сохранить шаблон</v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="templateHeaders"
                :items="TEMPLATES"
                item-key="id"
                class="elevation-1"
                style="margin-top: 10px"
              >
                <template v-slot:item.actions="{ item }">
                  <div style="display: flex; justify-content: flex-end;">
                    <v-icon small class="mr-2" @click="openEditItemTemplate(item)">mdi-pencil</v-icon>
                    <v-icon color="red" small @click="deleteItemTemplate(item)">mdi-delete</v-icon>
                  </div>
                </template>
              </v-data-table>
              <v-dialog v-model="dialogTemplate" max-width="500px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row style="margin-top: 20px; margin-bottom: 12px">
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
                        </v-col>
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemTemplate.name"
                            label
                            placeholder
                            dense
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span
                            style="padding-top: 10px; padding-right: 10px;"
                          >НДС на контрактную стоимость</span>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemTemplate.VATContract"
                            label
                            placeholder
                            dense
                            solo
                            append-icon="mdi-percent-outline"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span
                            style="padding-top: 10px; padding-right: 10px;"
                          >НДС на вознаграждение</span>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemTemplate.VATRevard"
                            label
                            placeholder
                            dense
                            solo
                            append-icon="mdi-percent-outline"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span style="padding-top: 10px; padding-right: 10px;">НДС на страховку</span>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemTemplate.VATInsurance"
                            label
                            placeholder
                            dense
                            solo
                            append-icon="mdi-percent-outline"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span style="padding-top: 10px; padding-right: 10px;">НДС на доп. расходы</span>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemTemplate.VATMore"
                            label
                            placeholder
                            dense
                            solo
                            append-icon="mdi-percent-outline"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="8"
                          offset="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <div style="display: flex">
                            <v-checkbox
                              style="margin-right: 17px; margin-top: 0"
                              v-model="editedItemTemplate.typeClient"
                              label="Физ. ливо"
                              value="0"
                            ></v-checkbox>
                            <v-checkbox
                              style="margin-top: 0"
                              v-model="editedItemTemplate.typeClient"
                              label="Юр. лицо"
                              value="1"
                            ></v-checkbox>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeChangeTemplate">Закрыть</v-btn>
                    <v-btn color="blue darken-1" text @click="changeTemplate">Сохранить</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            style="font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Ставка привлекаемого кредита</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <!-- <span
              style="display: block; margin-top: 25px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
            >Ставка привлекаемого кредита</span>
              <v-divider style="border-color: rgb(25 118 210)"></v-divider>-->
              <v-row style="margin-top: 20px;">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
                </v-col>
                <v-col cols="8" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field v-model="newAttractivenessRate.name" label placeholder dense solo></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">Ставка, %</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="newAttractivenessRate.rate"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px margin-bottom: 0; margin-bottom: 10px">
                <v-col
                  cols="8"
                  offset="4"
                  style="display: flex; justify-content: flex-end; padding-top: 4px; padding-bottom: 4px"
                >
                  <div style="display: flex">
                    <v-btn
                      @click="addAttractivenessRate"
                      style="margin-left: auto"
                      color="primary"
                      elevation="2"
                    >Сохранить ставку</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                :headers="attractivenessRateHeaders"
                :items="ATTRACTIVENESS_RATE"
                item-key="id"
                class="elevation-1"
                style="margin-top: 10px"
              >
                <template v-slot:item.actions="{ item }">
                  <div style="display: flex; justify-content: flex-end;">
                    <v-icon small class="mr-2" @click="openEditAttractivenessRate(item)">mdi-pencil</v-icon>
                    <v-icon color="red" small @click="deleteAttractivenessRate(item)">mdi-delete</v-icon>
                  </div>
                </template>
              </v-data-table>
              <v-dialog v-model="dialogAttractivenessRate" max-width="500px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row style="margin-top: 20px; margin-bottom: 12px">
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
                        </v-col>
                        <v-col
                          cols="8"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemAttractivenessRate.name"
                            label
                            placeholder
                            dense
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 0px">
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <span style="padding-top: 10px; padding-right: 10px;">Ставка, %</span>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; padding-top: 4px; padding-bottom: 4px"
                        >
                          <v-text-field
                            v-model="editedItemAttractivenessRate.rate"
                            label
                            placeholder
                            dense
                            solo
                            append-icon="mdi-percent-outline"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeChangeAttractivenessRate">Отмена</v-btn>
                    <v-btn color="blue darken-1" text @click="changeAttractivenessRate">Сохранить</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            style="font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
          >Частный случай договора (упрощенная процедура)</v-expansion-panel-header>
          <v-expansion-panel-content>
            <special-case />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import SpecialCase from "./catalog/SpecialCase.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      newTemplate: {
        name: "",
        typeClient: "0",
        VATContract: 0,
        VATRevard: 0,
        VATInsurance: 0,
        VATMore: 0
      },
      templateHeaders: [
        {
          text: "Наименование",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "", value: "actions" }
      ],
      dialogTemplate: false,
      attractivenessRate: [],
      editedItemTemplate: {
        name: "",
        typeClient: "0",
        VATContract: 0,
        VATRevard: 0,
        VATInsurance: 0,
        VATMore: 0
      },
      attractivenessRateHeaders: [
        {
          text: "Наименование",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "", value: "actions" }
      ],
      newAttractivenessRate: {
        name: "",
        rate: "0"
      },
      default: {
        AttractivenessRate: {
          name: "",
          rate: "0"
        },
        template: {
          name: "",
          typeClient: "0",
          VATContract: 0,
          VATRevard: 0,
          VATInsurance: 0,
          VATMore: 0
        }
      },
      editedItemAttractivenessRate: {
        name: "",
        rate: "0"
      },
      dialogAttractivenessRate: false
    };
  },
  components: {
    SpecialCase
  },
  computed: {
    ...mapGetters(["TEMPLATES", "ATTRACTIVENESS_RATE"])
  },
  methods: {
    ...mapActions([
      "TEMPLATES__GET",
      "TEMPLATES__ADD",
      "TEMPLATES__CHANGE",
      "TEMPLATES__DELETE",
      "ATTRACTIVENESS_RATE__GET",
      "ATTRACTIVENESS_RATE__ADD",
      "ATTRACTIVENESS_RATE__CHANGE",
      "ATTRACTIVENESS_RATE__DELETE"
    ]),
    addTemplate() {
      // this.template.push(this.newTemplate);
      this.TEMPLATES__ADD({ ...this.newTemplate });
      this.newTemplate = { ...this.default.template };
    },
    openEditItemTemplate(item) {
      console.log("ITEM = ", item);
      this.dialogTemplate = true;
      this.editedItemTemplate = { ...item };
    },
    changeTemplate() {
      console.log(this.editedItemTemplate, "-=-==-");
      this.TEMPLATES__CHANGE({
        data: this.editedItemTemplate,
        id: this.editedItemTemplate.id
      });
      this.dialogTemplate = false;
      this.editedItemTemplate = { ...this.default.template };
    },
    closeChangeTemplate() {
      this.dialogTemplate = false;
      this.editedItemTemplate = { ...this.default.template };
    },
    deleteItemTemplate(item) {
      console.log("DELETE item, ", item.id);
      this.TEMPLATES__DELETE(item.id);
    },
    addAttractivenessRate() {
      this.ATTRACTIVENESS_RATE__ADD({ ...this.newAttractivenessRate });
      this.newAttractivenessRate = {
        name: "",
        rate: "0"
      };
      // this.attractivenessRate.push(this.newAttractivenessRate);
      // fetch('https://dev56.support.by/leasingAPI/public/addAttractivenessRate', {
      // 	method: 'post',
      //   body: JSON.stringify(this.newAttractivenessRate)
      // }).then((res) => {
      // 	console.log(' GET - = ', res)
      // })
    },
    openEditAttractivenessRate(item) {
      console.log("ITEM = ", item);
      this.dialogAttractivenessRate = true;
      this.editedItemAttractivenessRate = { ...item };
    },
    closeChangeAttractivenessRate() {
      this.dialogAttractivenessRate = false;
      this.editedItemAttractivenessRate = {
        ...this.default.attractivenessRate
      };
    },
    changeAttractivenessRate() {
      this.ATTRACTIVENESS_RATE__CHANGE({
        data: this.editedItemAttractivenessRate,
        id: this.editedItemAttractivenessRate.id
      });
      this.dialogAttractivenessRate = false;
      this.editedItemAttractivenessRate = {
        ...this.default.attractivenessRate
      };
    },
    deleteAttractivenessRate(item) {
      this.ATTRACTIVENESS_RATE__DELETE(item.id);
    },

    testGETTemplate() {
      console.log("-=-=-=-=");
      this.TEMPLATES__GET();
    },
    testGETAttractivenessRate() {
      this.ATTRACTIVENESS_RATE__GET();
      // fetch(
      //   "https://dev56.support.by/leasingAPI/public/getAttractivenessRate",
      //   {
      //     method: "get"
      //   }
      // ).then(res => {
      //   console.log(" GET - = ", res);
      // });
    }
  }
};
</script>

<style scoped>
</style>