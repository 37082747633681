<template>
  <div>
    <div>
      <!-- <span
        style="display: block; margin-top: 25px; font-size: 1.2em; color: rgb(25 118 210); font-weight: 500"
      >Частный случай договора (упрощенная процедура)</span>
      <v-divider style="border-color: rgb(25 118 210)"></v-divider>-->
      <v-row style="margin-top: 20px;">
        <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
          <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
        </v-col>
        <v-col cols="8" style="display: flex; padding-top: 4px; padding-bottom: 4px">
          <v-text-field v-model="specialCase.name" label placeholder dense solo></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="12" md="12" style="display: flex;">
          <span style="padding-top: 10px; padding-right: 10px;">Участие клиента</span>
          <div style="display: flex">
            <v-checkbox
              v-for="item in involvementValues"
              :label="item.name"
              color="info"
              class="mr-5"
              style="margin-top: 6px"
              :value="item"
              :key="item.name"
              hide-details
              v-model="specialCase['involvementValues']"
            >
              <template v-slot:label>
                <div>
                  <div v-if="item.more">
                    <v-hover v-slot="{hover}">
                      <div>
                        <span>{{item.name}}</span>
                        <v-icon
                          v-if="hover"
                          color="red"
                          small
                          style="margin-bottom: 3px;"
                          @click="deleteMore('involvementValues', item)"
                        >mdi-close</v-icon>
                        <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                      </div>
                    </v-hover>
                  </div>
                  <div v-else>{{item.name}}</div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="height: 31px; width: 31px; margin-top: 7px"
                v-bind="attrs"
                v-on="on"
              >mdi-plus</v-icon>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" style="display: flex; justify-content: flex-start">
                      <div style="padding-top: 10px; padding-right: 10px;">Процент</div>
                      <v-text-field
                        label
                        solo
                        :append-icon="valuesDialogsMore.involvementValues.type == 'money' ? 'mdi-cash' : 'mdi-percent-outline'"
                        v-model="valuesDialogsMore.involvementValues.value"
                        @click:append="changeTypeInvolvement()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = false; clearFieldsDialog('involvementValues')}"
                  >Отмена</v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = !saveFieldsDialog('involvementValues')}"
                  >Добавить</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="12" md="12" style="display: flex;">
          <span
            style="dispay: block; width: 250px; padding-top: 0px; padding-right: 10px;"
          >Вознаграждение, %</span>
          <div style="display: flex">
            <v-text-field
              v-model="specialCase.reward"
              label
              placeholder
              solo
              dense
              type="number"
              append-icon="mdi-percent-outline"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row style="margin-top: 10px">
        <v-col cols="12" sm="12" md="12" style="display: flex; justify-content: flex-start">
          <span style="padding-top: 10px; padding-right: 10px;">Валюта</span>
          <div style="display: flex">
            <v-checkbox
              v-for="item in currencyValues"
              :label="item.name"
              color="info"
              :value="item.name"
              :key="item.name"
              hide-details
              class="mr-5"
              style="margin-top: 6px"
              v-model="specialCase.currencyValues"
            >
              <template v-slot:label>
                <div>
                  <div v-if="item.more">
                    <v-hover v-slot="{hover}">
                      <div>
                        <span>{{item.name}}</span>
                        <v-icon
                          v-if="hover"
                          color="red"
                          small
                          style="margin-bottom: 3px;"
                          @click="deleteMore('currencyValues', item)"
                        >mdi-close</v-icon>
                        <div v-else style="width: 16px; height: 10px; display: inline-block;"></div>
                      </div>
                    </v-hover>
                  </div>
                  <div v-else>{{item.name}}</div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="height: 31px; width: 31px; margin-top: 7px"
                v-bind="attrs"
                v-on="on"
                @click="() => updateDataDialog('currencyValues')"
              >mdi-plus</v-icon>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" style="display: flex; justify-content: flex-start">
                      <div style="padding-top: 10px; padding-right: 10px;">Валюта</div>
                      <v-select
                        :value="valuesDialogsMore.currencyValues.name"
                        :items="currencyValuesMore"
                        item-text="name"
                        item-value="name"
                        solo
                        @change="(val) =>changeCurrentyMore('currencyValues', val)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = false; clearFieldsDialog('currencyValues')}"
                  >Отмена</v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="() =>{dialog.value = !saveFieldsDialog('currencyValues')}"
                  >Добавить</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: 0px">
        <v-col cols="12" sm="12" md="12" style="display: flex; flex-direction: column;">
          <span
            style="padding-top: 10px; padding-right: 10px; color: rgb(25 118 210); font-weight: 500"
          >Срок лизинга</span>
          <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
            <v-checkbox
              v-for="item in leaseTermValues"
              :label="item.name"
              color="info"
              class="mr-5"
              style="margin-top: 12px; width: 30%"
              :value="item.name"
              :key="item.name"
              hide-details
              v-model="specialCase['leaseTermValues']"
            ></v-checkbox>
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <div style="width: 30%; height: 45px;">
                  <v-icon
                    style="height: 31px; width: 31px; margin-top: 7px; margin-left: -4px;"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-plus</v-icon>
                </div>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="text-h5"></v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" style="display: flex; justify-content: flex-start">
                        <div style="padding-top: 10px; padding-right: 10px;">Месяцев</div>
                        <v-text-field
                          label="5"
                          solo
                          append
                          v-model="valuesDialogsMore.leaseTermValues.value"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="() =>{dialog.value = false; clearFieldsDialog('leaseTermValues')}"
                    >Отмена</v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="() =>{dialog.value = !saveFieldsDialog('leaseTermValues')}"
                    >Добавить</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
      <div style="margin-top:10px; display: flex; ">
          <v-btn
        @click="addSpecialCase"
        style="margin-left: auto"
        color="primary"
        elevation="2"
      >Сохранить шаблон</v-btn>
      </div>

      <v-data-table
        :headers="SpecialCaseHeaders"
        :items="SPECIAL_CASES"
        item-key="id"
        class="elevation-1"
        style="margin-top: 10px"
      >
        <template v-slot:item.actions="{ item }">
          <div style="display: flex; justify-content: flex-end;">
            <!-- <v-icon small class="mr-2" @click="openEditAttractivenessRate(item)">mdi-pencil</v-icon> -->
            <v-icon color="red" small @click="deleteSpecialCase(item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
      <!-- <v-dialog v-model="dialogAttractivenessRate" max-width="500px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row style="margin-top: 20px; margin-bottom: 12px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">Наименование</span>
                </v-col>
                <v-col cols="8" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="editedItemAttractivenessRate.name"
                    label
                    placeholder
                    dense
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0px">
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <span style="padding-top: 10px; padding-right: 10px;">Ставка, %</span>
                </v-col>
                <v-col cols="4" style="display: flex; padding-top: 4px; padding-bottom: 4px">
                  <v-text-field
                    v-model="editedItemAttractivenessRate.rate"
                    label
                    placeholder
                    dense
                    solo
                    append-icon="mdi-percent-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeChangeAttractivenessRate">Отмена</v-btn>
            <v-btn color="blue darken-1" text @click="changeAttractivenessRate">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>-->
      <v-btn @click="test">TEST</v-btn>
    </div>
    <v-snackbar v-model="onAlert" :multi-line="true">
      {{ alertMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="onAlert = false">Закрыть</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      specialCase: {
        name: "",
        involvementValues: [],
        reward: "",
        currencyValues: [],
        leaseTermValues: []
      },
      moreFields: {
        currencyValues: [],
        involvementValues: [],
        leaseTermValues: []
      },
      valuesDialogsMore: {
        currencyValues: {
          name: "",
          value: ""
        },
        involvementValues: {
          name: "",
          value: "",
          type: "percent"
        },
        leaseTermValues: {
          name: "",
          value: ""
        }
      },
      SpecialCaseHeaders: [
        {
          text: "Наименование",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "", value: "actions" }
      ],
      onAlert: false,
      alertMessage: ""
    };
  },
  computed: {
    ...mapGetters([
      "CURRENTY__BYN",
      "CURRENTY__default",
      "CURRENTY__more",
      "DEFAULT_FIELDS",
      "SPECIAL_CASES"
    ]),
    involvementValues() {
      return [
        ...this.DEFAULT_FIELDS.involvementValues,
        ...this.moreFields.involvementValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    currencyValues() {
      return [
        ...this.CURRENTY__BYN,
        ...this.CURRENTY__default,
        ...this.moreFields.currencyValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    },
    currencyValuesMore() {
      return this.CURRENTY__more.filter(el => {
        return !this.currencyValues.find(elUs => elUs.name == el.name);
      });
    },
    leaseTermValues() {
      return [
        ...this.DEFAULT_FIELDS.leaseTermValues,
        ...this.moreFields.leaseTermValues.map(el => ({
          ...el,
          more: true
        }))
      ];
    }
  },
  methods: {
    ...mapActions(["SPECIAL_CASE__DELETE", "SPECIAL_CASE__ADD"]),
    changeCurrentyMore(typeDialog, val) {
      this.valuesDialogsMore[typeDialog] = {
        ...this.CURRENTY__more.find(el => el.name == val)
      };
    },
    deleteMore(typeDialog, val) {
      let deleteId = this.moreFields[typeDialog].findIndex(
        el => el.name == val.name
      );
      console.log("CALC", this.specialCase, "=", val);
      let index = this.specialCase[typeDialog].findIndex(el => el == val.name);
      if (index != -1) {
        this.specialCase[typeDialog].splice(index, 1);
      }

      this.moreFields[typeDialog].splice(deleteId, 1);
    },

    updateDataDialog(typeDialog) {
      this.valuesDialogsMore[typeDialog] = { ...this[typeDialog + "More"][0] };
    },
    saveFieldsDialog(typeDialog) {
      if (typeDialog == "involvementValues") {
        if (this.valuesDialogsMore[typeDialog].type == "money") {
          this.valuesDialogsMore[typeDialog].name = this.valuesDialogsMore[
            typeDialog
          ].value;
        } else {
          this.valuesDialogsMore[typeDialog].name =
            this.valuesDialogsMore[typeDialog].value + "%";
        }
      }
      if (typeDialog == "leaseTermValues") {
        this.valuesDialogsMore[typeDialog].name =
          this.valuesDialogsMore[typeDialog].value + " месяцев";
      }
      if (
        ![
          ...this.DEFAULT_FIELDS[typeDialog],
          ...this.moreFields[typeDialog]
        ].find(el => el.name == this.valuesDialogsMore[typeDialog].name) &&
        this.valuesDialogsMore[typeDialog].name != ""
      ) {
        this.moreFields[typeDialog].push({
          ...this.valuesDialogsMore[typeDialog]
        });
        this.clearFieldsDialog(typeDialog);
        return true;
      } else {
        return false;
      }
    },
    clearFieldsDialog(typeDialog) {
      for (let key in this.valuesDialogsMore[typeDialog]) {
        this.valuesDialogsMore[typeDialog][key] = "";
      }
    },

    deleteSpecialCase(item) {
      this.SPECIAL_CASE__DELETE(item.id);
    },

    addSpecialCase() {
      let checkLoad = true;
      let message = {
          name: 'Не заполнено название'
      }
      if (this.specialCase.name == '') {
          this.alertMessage = message.name;
          checkLoad = false;
      }

      if (checkLoad) {
        this.SPECIAL_CASE__ADD({ ...this.specialCase });
        this.specialCase = {
          name: "",
          involvementValues: [],
          reward: "",
          currencyValues: [],
          leaseTermValues: []
        };
      } else {
          this.onAlert = true;
      }
      // this.attractivenessRate.push(this.newAttractivenessRate);
      // fetch('https://dev56.support.by/leasingAPI/public/addAttractivenessRate', {
      // 	method: 'post',
      //   body: JSON.stringify(this.newAttractivenessRate)
      // }).then((res) => {
      // 	console.log(' GET - = ', res)
      // })
    },

    test() {
      console.log(this.specialCase, " SPECIAL");
    }
  }
};
</script>

<style scoped>
</style>