import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings'
import templates from './modules/templates'
import attractivenessRate from './modules/attractivenessRate'
import specialCase from './modules/specialCase'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        defaultFields: {
            typeClients: [
              {
                text: "Физ. лицо",
                value: "0"
              },
              {
                text: "Юр. лицо",
                value: "1"
              }
            ],
            currencyValues: [
              { name: "BYN", value: 1.0 },
              { name: "USD", value: 1.0 },
              { name: "EUR", value: 1.0 },
              { name: "RUB", value: 1.0 }
            ],
            involvementValues: [
              { name: "10%", value: 10, type: "percent" },
              { name: "15%", value: 15, type: "percent" },
              { name: "20%", value: 20, type: "percent" },
              { name: "25%", value: 25, type: "percent" },
              { name: "30%", value: 30, type: "percent" },
              { name: "40%", value: 40, type: "percent" }
            ],
            contractCurrencyValues: [
              { name: "BYN", value: 1.0 },
              { name: "USD", value: 1.0 },
              { name: "EUR", value: 1.0 },
              { name: "RUB", value: 1.0 }
            ],
            leaseTermValues: [
              { name: "6 месяцев", value: 6 },
              { name: "12 месяцев", value: 12 },
              { name: "18 месяцев", value: 18 },
              { name: "24 месяца", value: 24 },
              { name: "30 месяцев", value: 30 },
              { name: "36 месяцев", value: 36 },
              { name: "48 месяцев", value: 48 },
              { name: "60 месяцев", value: 60 }
            ],
            repaymentTypeValues: [
              { name: "Ускоренный", value: 0 },
              { name: "Равный", value: 1 },
              { name: "Медленный", value: 2 }
            ],
            insuranceTypeValues: [
              { name: "Помесячно", value: "monthly" },
              { name: "Поквартально", value: "quarterly" },
              { name: "Единовременно", value: "one" },
              { name: "Без страховки", value: "not" }
            ],
            depreciationValues: [{ name: "Стандартные", value: "standart" }, {name: "Равномерные", value: "peer"}]
          },
        url: 'https://dev56.support.by/leasingAPI/public/'
    },
    getters: {
        DEFAULT_FIELDS(state) {
            return {...state.defaultFields}
        }
    },
    mutations: {},
    actions: {},
    modules: {
        settings,
        templates,
        attractivenessRate,
        specialCase
    }
})