export default {
	state: {
	},
	getters: {
		CURRENTY__BYN(state, getters , rootState) {
			console.log('', rootState)
			return rootState.settings.currenty.BYN
		},
		CURRENTY__default(state, getters , rootState) {
			return rootState.settings.currenty.default
		},
		CURRENTY__more(state, getters , rootState) {
			return rootState.settings.currenty.more.map(el => ({...el, type: 'more'}))
		}
	},
	mutations: {
		CURRENTY_addMore(state, val) {
			console.log(this, '--=', state);
			this.state.settings.currenty.more.push({
				name: val,
				value: null
			})
		},
		CURRENTY__CHANGE(state, val) {
			let changeCurrenty = {}
			if (val.type == 'more') {
				changeCurrenty = this.state.settings.currenty.more.find(el => el.name == val.name)
			} else {
				changeCurrenty = this.state.settings.currenty.default.find(el => el.name == val.name)
			}
			changeCurrenty[val.type] = val.value;
		},
		CURRENTY__DELETE(state, val) {
			let id = this.state.settings.currenty.more.findIndex(el => el.name == val.name);
			if (id == '-1') {
				console.log('-=-=-=-= ERROR DELETE', )
			} else {
				this.state.settings.currenty.more.splice(id, 1)
			}
			

		}


	},
	actions: {

	}
}